// STB Base
$small: 500px;

.app-main .app-main__inner {
    // padding: 15px 15px 0;
    flex: 1; 
}
.d-flex {
    display: flex !important;
}
.justify-content-between {
    justify-content: space-between !important;
}
.align-items-center {
    align-items: center !important;
}
.pending-approval-user {
    background: #fff;
    // color: #e71f9d;
    padding: 6px 24px;
    text-align: center;
    border: 1px solid #7714df;
    border-radius: 4px !important;
    color: #7714df;
    // font-weight: 600;
    &:hover{
        border: 1px solid #7714df;
        background-color: #7714df;
        color: #fff;
        font-weight: bold;
    }
}

.form-control-feedback {
        top: 10px !important;
    }
.MuiTablePagination-root .MuiToolbar-root p {
  margin-bottom: 0!important;
}
.useractive{
    background: #3CB574;
    // padding: 7px 16px;
}
.userlocked{
    max-width: 120px;
    width: 110px;
}
.userdeactive{
    background: #b8b8b8 !important;
}
.userexpired{
    background: red!important;
    color: #fff; 
}
.usertype-admin {
    color: #9ecd6a;
}
.switchuser-active span:nth-child(2){
    background-color:#3cb574 !important;
}
.switchuser-inactive span:nth-child(2){
    background-color:#ff0000 !important;
}
.edit-icon {
    color: #5373dc;
  }
  .app-main__outer {
    overflow-x: auto !important;
  }
.pop-up-design{
    h3{
        margin-bottom: 0px !important;
        font-weight: normal !important;
    }
    p{
    
    font-weight: normal !important;
    }
}

.breadcrumb{
    width: 100%;
    background: none;
    // background-color: #f1f4f6;
    padding-left: 0% !important;
    ul{
        padding-left: 0% !important;
        list-style: none;
        margin:0;
        li{
            width: auto;
            color: #889197;
            float: left;
            cursor: pointer;
            font-size: 0.9rem;
            span{
                margin: 0 0.3rem 0 0.1rem;
           }
           a{
            color: black;
           }
       }
        li:nth-last-child(1) {
            color: #5e03c7;
            cursor:inherit;
       }
   }
}

  .search-input .input-search {
    height: 40px;
    border-radius: 4px;
    border: 1px solid #ebebeb;
    padding: 0 30px 0 11px;
    outline-style: none;
}

.user-status {
    max-width: 90px;    
    text-align: center;
    border-radius: 4px;
    // padding: 5px 10px;
    width: 70px;
    color: #fff;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sub-header {
    h1{
        font-size: 18px;
        font-weight: 400;
        color: #000;
    }
}

.createuser {
    background: #fff;
    // color: #e71f9d;
    padding: 6px 24px;
    text-align: center;
    border: 1px solid #e10592;
    border-radius: 4px !important;
    color: #e10592;
    // font-weight: 600;
    &:hover{
        border: 1px solid #e10592;
        background-color: #e10592;
        color: #fff;
        font-weight: bold;
    }
}

.Status-btn-user{
    background: #fff;
    border: 1px solid #e4149a;
    border-radius: 4px !important;
    transition: none;
    padding: 6px 24px !important;
    font-size: 12px;
    font-weight: 400;
    color: #e4149a;
}

.Status-btn-user:hover,.Status-btn-user:active {
    border: 1px solid #e4149a;
    background: #e4149a ;
    color: #fff !important;
    border-radius: 4px;
    // font-weight: 400;
}

.top-right-filter{
    // float: right;
    // margin-bottom:15px;
    display: flex;
    justify-content: end;
    align-items: center;
    .Status-btn-user{
        background: #fff;
        border-radius: 4px;
        color: #9B9B9B;
        margin: 0 14px 14px 0;
        float: left;
        min-width: 94px;
        line-height: 20px;
        border: none;
        border: 1px solid #fff !important;
    }
    .Status-btn-user:hover{
        color:#E10592 !important;
        border: 1px solid #E10592 !important;
        background:#fff;
    }
    .Status-btn-user-active{
        color: #E10592 !important;
        border: 1px solid #E10592 !important;
    }
    .clander-bg{
        width: 40px;
        height: 40px;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #6B00DD;
        background: #fff;
        text-align: center;
        line-height: 24px;
        margin: 0px;
    }
    .filter-application-level:hover{
        background: #e10092;
        color: white;
        border: none;
    }
    
}

.switchStyleuser{
    .MuiSwitch-thumb{
    margin-top: 9px !important;
    margin-left: 10px !important;
    }
    // .MuiSwitch-thumb{
    //     width: 3px !important;
    //     height: 3px !important;
    // }
}