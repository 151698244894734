.menu-one {
	top: 181px;
	left: 72px;
	width: 100%;
	height: 500px;
	mix-blend-mode: normal;
	opacity: 1;
	overflow-y: auto;
}

.menu-one::-webkit-scrollbar {
	width: 1px
}

.menu-one::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.menu-one::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	outline: 1px solid slategrey;
}