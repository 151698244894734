.role-checklist-list-style{
    // margin-left: 10px;
    // display: block !important;
    list-style-type: none;
    // margin-left: -25px;
    li {
        div{
            display: block !important;
        }
    }
}
.role-checklist-border{
    margin-left: 10px;
    min-height: 40px;
    height:auto;
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    margin-right: 16px;
    list-style-type: none;
    
}
.role-checklist{
    // width: 100%;
    // float: left;  
    // font-size: 12px;
    text-align: start !important;
    
    // margin: 10px 10px 7px 10px;
      ul{
        list-style: none;
        margin: 0;
        padding: 0px;
        li{
            width: 80%;
            margin: 0 0 0 0;
            list-style: none;
            label {
                margin: 0px !important;
            }
          }
      }
  
      
}
.modal-header {
    padding: 0px !important;
}
.rct-scroll {
    div:nth-child(1){
    // overflow-x:auto !important;
}
}

.selectAdd{
    margin-left: 1px !important;
    margin-bottom: 0px !important;
}

.drpdw-menu {
    min-width: 11rem !important;
}
.demo-simple-select {
    .MuiButtonBase-root {
        display: flex !important;
        justify-content: flex-start !important;
        align-items: center !important;
        padding: 6px 16px;
    }
}

.update{
    margin: 6px 0px 20px 15px;
    // margin-bottom: 20px;
    // margin-top: 10px;
    padding: 6px 15px;
    background: #5e03c7;
    color: #fff;
    border-radius:4px;
    border: none;
    // margin-left: 15px;
}
