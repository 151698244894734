.center-container{
    background-color:white;    
    border-radius: 4px; 
    border: none;
    transition: all 0.4s ease-in-out 0s;
    margin-bottom: 24px;
    position: relative;
    // padding: 1rem 1.5rem 1.5rem 1rem;
    // margin-top: 30px;
    height: 60vh;
    .validationerror {
        color: #ff0000 !important;
        font-size: 14px;
        text-indent: 1px !important;
    }
    .on-off-eye {
        position: absolute;
        right: 25px;
        top: 46px;
        z-index: 1;
    }
}
.newpassword{
     padding: 0.375rem 2rem 0.375rem 0.785em;
}

.cancel-btn-chng {
    background-color: transparent;
    border: transparent;
}