$small: 500px;

.create-user {
    background: #fff;
    padding: 15px;
    height: 70vh;
    border-radius: 4px;
}

.form-list {
    label {
        font-weight: 500;
        color: #070707;
    }
}

.row-user {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.d-block {
    display: block !important;
}

.d-md-flex {
    display: flex !important;
}

.center-container {
    background-color: white;
    border-radius: 4px;
    border: none;
    transition: all 0.4s ease-in-out 0s;
    margin-bottom: 24px;
    position: relative;
    // padding: 1rem 1.5rem 1.5rem 1rem;
    // margin-top: 30px;
    height: 60vh;

    .validationerror {
        color: #ff0000 !important;
        font-size: 14px;
        text-indent: 1px !important;
    }

    .on-off-eye {
        position: absolute;
        right: 25px;
        top: 46px;
        z-index: 1;
    }
}

.switchuser-active span:nth-child(2) {
    background-color: #3cb574 !important;
}

.switchuser-inactive span:nth-child(2) {
    background-color: #ff0000 !important;
}

@media (min-width: 768px) {
    .d-md-none {
        display: none !important;
    }

    .d-md-inline {
        display: inline !important;
    }

    .d-md-inline-block {
        display: inline-block !important;
    }

    .d-md-block {
        display: block !important;
    }

    .d-md-table {
        display: table !important;
    }

    .d-md-table-row {
        display: table-row !important;
    }

    .d-md-table-cell {
        display: table-cell !important;
    }

    .d-md-flex {
        display: flex !important;
    }

    .d-md-inline-flex {
        display: inline-flex !important;
    }
}

@media screen and (max-width: $small) {
    .create-user {
        height: auto;
    }
}

.person-icon {
    font-size: larger !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }

    .mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }


.back-btn {
    border: 1px solid #e73baa !important;
    padding: 4px 38px !important;
    color: #e73baa !important;
}

.back-btn:hover {
    background-color: #e73baa !important;
    color: #fff !important;
}

.switchStyle{
    .MuiSwitch-switchBase{
    margin-top: 9px !important;
    margin-left: 10px !important;
    }
    .MuiSwitch-thumb{
        width: 3px !important;
        height: 3px !important;
    }
}